<template>
  <v-container fluid class="px-6 py-6">
    <v-row class="mt-8">
      <v-col cols="12">
        <div>
          <v-card class="card-shadow border-radius-xl">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn
                @click="back"
                elevation="0"
                :ripple="false"
                dark
                height="43"
                color="blue"
                class="text-capitalize btn-ls py-3 px-6 mx-2 blue"
                >Back</v-btn
              ><v-btn
                @click="download"
                dark
                class="
                  font-weight-bold
                  text-capitalize
                  btn-primary
                  bg-success
                  py-5
                  px-5
                  shadow
                "
              >
                <v-icon size="20" class="text-white mr-1">
                  fas fa-cloud-download-alt
                </v-icon>
                Download</v-btn
              > </v-card-title
            ><v-card-text class="px-0 py-0">
              <v-row class="pa-4 ms-3">
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label
                      class="text-md font-weight-bolder ms-1 mandatory ml-2"
                      >Mode Of Transport</label
                    >
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{
                      mode_of_transport
                    }}</label>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label
                      class="text-md font-weight-bolder ms-1 mandatory ml-2"
                      >Vehicle NO</label
                    >
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{
                      vehicle_no
                    }}</label>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row class="tittle__row">
                    <label
                      class="text-md font-weight-bolder ms-1 mandatory ml-2"
                      >Driver Contact No</label
                    >
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{
                      driver_contact_no
                    }}</label>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="card-padding mt-5">
              <v-data-table
                :headers="header"
                :items="dispatchChild"
                class="table"
                :footer-props="{
                  'items-per-page-options': [100, 200, 500, -1],
                }"
                mobile-breakpoint="0"
                disable-pagination
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row class="mb-8">
                      <v-col offset="6">
                        <label
                          class="text-md font-weight-bolder ms-1 mandatory ml-2"
                        >
                          Order Packages : {{ sumOrderPack }} Bags</label
                        >
                        <br />
                        <label
                          class="text-md font-weight-bolder ms-1 mandatory ml-2"
                        >
                          Order Quantity : {{ sumOrderKgs }} Kgs</label
                        >
                        <br />
                        <br />
                      </v-col>
                      <v-col v-if="sataus == 'RECIEVED'">
                        <label
                          v-if="sataus == 'RECIEVED'"
                          class="text-md font-weight-bolder ms-1 mandatory ml-2"
                        >
                          Received Packages : {{ sumRecvdPack }} Kgs</label
                        >
                        <br />
                        <label
                          v-if="sataus == 'RECIEVED'"
                          class="text-md font-weight-bolder ms-1 mandatory ml-2"
                        >
                          Received Quantity : {{ sumRecvdKgs }} Kgs</label
                        ><br />
                        <br />
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <span>
                    <v-chip
                      label
                      small
                      :ripple="false"
                      :color="syncStatus(item).color"
                    >
                      {{ syncStatus(item).label }}
                    </v-chip>
                  </span>
                </template></v-data-table
              ></v-card-text
            >
            <v-card-actions> </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "../api";
import { json2excel } from "js2excel";
export default {
  data() {
    return {
      id: this.$route.params.item.id,
      item: this.$route.params.item,
      sumOrderPack: 0,
      sumOrderKgs: 0,
      sumRecvdPack: 0,
      sumRecvdKgs: 0,
      sataus: "CONFIRMED",
      header: [],
      dispatchChildHeader: [
        // {
        //   text: "Batch No",
        //   value: "batch_no",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "left",
        //   sortable: false,
        // },
        {
          text: "Mark",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Grade",
          value: "grade_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Invoice",
          value: "invoice_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Ordered(Packages)",
          value: "ordered_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Ordered(Kgs)",
          value: "ordered_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "status",
          value: "status",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
      ],
      recivedHeader: [
        {
          text: "Received(Packages)",
          value: "received_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Received(Kgs)",
          value: "received_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
      ],
      dispatchChild: [],
      vehicle_no: "",
      mode_of_transport: "",
      driver_contact_no: "",
    };
  },
  methods: {
    async viewDetails() {
      if (this.id == null) {
        this.back();
      }
      this.dispatchChild = await api.viewDetails(this.id);
      this.vehicle_no = this.dispatchChild[0].vehicle_no;
      this.mode_of_transport = this.dispatchChild[0].mode_of_transport;
      this.driver_contact_no = this.dispatchChild[0].driver_contact_no;
      if (this.dispatchChild.length > 0) {
        this.sumOrderPack = 0;
        this.sumOrderKgs = 0;
        this.sumRecvdPack = 0;
        this.sumRecvdKgs = 0;

        for await (const el of this.dispatchChild) {
          if (el.status == "RECIEVED") {
            this.sataus = "RECIEVED";
          }
          this.sumOrderKgs += parseFloat(el.ordered_quantity);
          this.sumOrderPack += parseFloat(el.ordered_no_of_packages);
          this.sumRecvdPack += parseFloat(el.received_no_of_packages);
          this.sumRecvdKgs += parseFloat(el.received_quantity);
        }

        if (this.sataus == "RECIEVED") {
          this.header = this.dispatchChildHeader.concat(this.recivedHeader);
        } else {
          this.header = this.dispatchChildHeader;
        }
        //
      } else {
        this.noDataAlert("No Data Found");
      }
    },
    syncStatus(item) {
      if (item.status === "PENDING") {
        return { label: "PENDING", color: "rgba(198, 128, 77, 0.12)" };
      } else if (item.status === "RECIEVED") {
        return { label: "RECEIVED", color: "rgba(105, 240, 174, 0.5)" };
      } else if (item.status === "CONFIRM") {
        return { label: "CONFIRMED", color: "rgb(0, 204, 255, 0.5)" };
      } else {
        return { label: item.status, color: "rgba(237, 63, 59, 0.5)" };
      }
    },
    back() {
      this.$router.push({ name: "History" });
    },
    async download() {
      await api.dispatchDownload(this.item.id, this.item.dispatch_code);
    },
  },
  created() {
    this.viewDetails();
  },
};
</script>

<style></style>
